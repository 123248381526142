import React, { useCallback, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { useStyles } from "./HabitsDetails.style";
import {
  TRANSPORTATION_ICON,
  FEEDING_ICON,
} from "images/AWS/habits/screenCategory";
import MyBalanceTable from "../Habits/MyBalance/MyBalanceTable";
import { getFinerioTransactions, updateUser } from "@apollo";
import { finerioTransactionsByCategory, parseNumber } from "@utils";
import _ from "lodash";
import { CloseRounded } from "@material-ui/icons";
import { ButtonPrimary, NumericInput } from "@components";
import { useForm } from "@hooks";
import { useApolloClient } from "@apollo/client";
import { currentUserPreferences } from "@apollo";

interface habitsDetailsProps {
  title: string;
  amount: number;
  backgroundColor: string;
  icon: string;
}

const HabitsDetail = ({
  title,
  amount,
  backgroundColor,
  icon,
}: habitsDetailsProps) => {
  const isMobile = useMediaQuery("(max-width: 1280px)");

  const buttonHandler = () => {
    navigate(Route.finance + "?#habits");
  };

  const classes = useStyles({ color: backgroundColor });

  const client = useApolloClient();
  const [form, , , handleChange] = useForm({});
  const preferences = currentUserPreferences();

  const { finerioTransactions } = getFinerioTransactions();
  const finerioData = finerioTransactionsByCategory(finerioTransactions);

  const dataToUse: { categories: any; date: any; expense: any; income: any }[] =
    [];

  finerioData.forEach(
    (element: { categories: any[]; date: any; expense: any; income: any }) => {
      const categories = element.categories?.find((obj) => obj.name === title);

      dataToUse.push({
        categories: [categories],
        date: element.date,
        expense: categories?.expense ?? 0,
        income: categories?.income ?? 0,
      });
    }
  );

  const getBudgetGoal = (categoryName: string) => {
    const budgetGoal = preferences.budgetGoals?.find(
      (item) => Object.keys(item)[0] === categoryName
    );
    return budgetGoal ? budgetGoal[categoryName] : 0;
  };

  const lastGoal = getBudgetGoal(title);

  const [goal, setGoal] = useState(lastGoal);

  const [openGoalPopUp, setOpenGoalPopUp] = useState(false);

  const goalButtonHandler = () => {
    setOpenGoalPopUp(!openGoalPopUp);
  };

  const handlePopUpOnClick = useCallback(
    (goal) => {
      const newBudgetGoal = {
        [title]: goal,
      };
      // search if the category already exists
      if (preferences === undefined || preferences.budgetGoals === undefined) {
        preferences.budgetGoals = [];
      }
      const budgetTitles = preferences?.budgetGoals?.map(
        (obj) => Object.keys(obj)[0]
      );
      if (budgetTitles?.includes(title)) {
        // if it does, replace it
        const newBudgetGoals = preferences?.budgetGoals.map((obj) => {
          if (Object.keys(obj)[0] === title) {
            return newBudgetGoal;
          }
          return obj;
        });
        form.preferences = {
          onBoarding: preferences?.onBoarding,
          budgetGoals: newBudgetGoals,
        };
      } else {
        // if not, add it to the array
        const newBudgetGoals = [...preferences?.budgetGoals, newBudgetGoal];
        form.preferences = {
          onBoarding: preferences?.onBoarding,
          budgetGoals: newBudgetGoals,
        };
      }
      setOpenGoalPopUp(false);
      updateUser(form, false, client);
    },
    [form, preferences, title, client]
  );

  return (
    <div className={classes.myHabits}>
      <div className={classes.titleRectangle}>
        <button className={classes.buttonBack1} onClick={buttonHandler}>
          <div className={classes.buttonBackContainer}>
            <KeyboardArrowLeft onClick={buttonHandler} />
          </div>
        </button>
        <h1 className={classes.habitTitle}> Mis hábitos </h1>
      </div>

      <div className={classes.habitDetail}>
        <p className={classes.habitCategory}>{title}</p>

        <div className={classes.habitCard}>
          <button className={classes.categoryIcon}>
            <img src={icon} className={classes.backIcon} />
          </button>

          <div className={classes.totalMetrics}>
            <p className={classes.totalAmount}>Total: ${parseNumber(amount)}</p>

            <div className={classes.vectorGray}>
              <div className={classes.vectorPurple}></div>
            </div>
            <h6 className={classes.averageSpending}>
              Meta de gasto: $
              {goal === 0 || null ? parseNumber(lastGoal) : parseNumber(goal)}
            </h6>
          </div>
        </div>
        <div className={classes.tableContainer}>
          <MyBalanceTable barColor={backgroundColor} data={dataToUse} />
        </div>

        {!isMobile && (
          <div className={classes.backToHabits}>
            <button className={classes.buttonBack2} onClick={buttonHandler}>
              <p className={classes.backToHabitsText}>Volver a Mis Hábitos</p>
            </button>
          </div>
        )}
        <div className={classes.addGoal}>
          <button className={classes.buttonAddGoal} onClick={goalButtonHandler}>
            <h2 className={classes.addGoalText}>Agregar meta</h2>
          </button>
        </div>
      </div>
      <div
        className={
          openGoalPopUp ? classes.popUpContainer : classes.hiddenDisplay
        }
      >
        <div className={classes.outerBorder}>
          <div className={classes.popUp}>
            <div className={classes.itemRow}>
              <h3 className={classes.rokipediaPopUpTitle}>Meta</h3>
              <button onClick={goalButtonHandler}>
                <CloseRounded htmlColor="#393E44" />
              </button>
            </div>
            <div className={classes.popUpVector}></div>

            <p className={classes.subtitle}>
              Agrega o actualiza la meta de la categoría {title}:
            </p>
            <div className={classes.goalInput}>
              <NumericInput
                className="input"
                name="goal"
                value={goal}
                onChange={(e) => {
                  setGoal(e.target.value);
                }}
              />
            </div>
            <div className={classes.buttonGotItContainer}>
              <ButtonPrimary
                className={classes.buttonGotIt}
                onClick={() => {
                  handlePopUpOnClick(goal);
                }}
                text="Agregar meta"
                secondaryVersion
                noDegraded
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HabitsDetail;
