import React, { useEffect, useState } from "react";
import { makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import { isBrowser } from "@utils";
import { Footer, SEO } from "@components/Structural";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import HabitsDetail from "@components/FinancialPortal/HabitsDetails/HabitsDetails";
import { categorysList } from "@data";
import MovementItem from "@components/FinancialPortal/Habits/MovementItem/MovementItem";
import { useAuth } from "@hooks";

const HabitsDetails = ({ location }) => {
  useAuth({ redirectIfNotLogged: true });

  const isMobile = useMediaQuery("(max-width: 1280px)");
  const classes = useStyles();
  if (isBrowser() && !location?.state) {
    navigate(Route.financialPortal);
  }
  const title = isBrowser() ? location?.state.name : "";
  const amount = isBrowser() ? location?.state.amount : "";
  const total = isBrowser() ? location?.state.total : "";
  const percentage = isBrowser() ? location?.state.percentage : "";
  const transactions = isBrowser() ? location?.state.transactions : [];
  const average = isBrowser()
    ? location?.state.average
    : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  const category = categorysList.find((item) => item.name === title);

  return (
    <>
      <SEO
        title="Mis hábitos - Detalles de la categoría"
        description="Analiza los gastos de cada categoría y agregar metas mensuales a cada una."
      />
      <div className={classes.wholeHabitPage}>
        <HabitsDetail
          title={title}
          amount={amount}
          backgroundColor={category?.backgroundColor}
          averageSpending={average}
          icon={category?.image}
        />
        <MovementItem data={transactions} />
      </div>
      {!isMobile && <Footer className={`${classes.footer}`} />}
    </>
  );
};

export default HabitsDetails;

export const useStyles = makeStyles<Theme>((theme) => ({
  wholeHabitPage: {
    padding: "3rem 2rem 4rem 2rem",
    display: "flex",
    justifyContent: "center",
    gap: "4rem",
    [theme.breakpoints.down(1280)]: {
      flexDirection: "column",
      padding: "0 0 0 0",
    },
  },
  supplementaryFooter: {
    margin: "20px",
    clear: "both",
  },
  disabled: {
    opacity: 0.5,
  },
  addMore: {
    display: "flex",
    alignItems: "center",
    fontFamily: theme.typography.fontFamily,
    cursor: "pointer",
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
  addCard: {
    display: "grid",
    width: 184,
    gap: 10,
    "& label": {
      top: -7,
    },
    "& .input": {
      "&  >div": {
        height: 33,
        width: 184,
      },
      "& input": {
        height: "0px!important",
      },
    },
    "& div": {
      gap: 10,
      display: "grid",
      "&  >div": {
        height: 33,
        width: 184,
      },
    },
  },
}));
